// @flow strict

import React, { type Node } from "react";

import { ActionIcon, Tooltip, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconMapPlus, IconResize, IconCircleMinus } from "@tabler/icons-react";

import {
  useAppContext,
  ACTION_CLEAR_ALL,
  ACTION_SET_FOCUS,
} from "src/AppContext";
import classes from "src/styles/Panel.module.css";
import AddGeometryModal from "src/components/AddGeometryModal";
import PanelGeoList from "src/components/PanelGeoList";

const Panel = (): Node => {
  const {
    state: { geometries },
    dispatch,
  } = useAppContext();

  const [opened, { open, close }] = useDisclosure(false);

  const openModal = () =>
    modals.openConfirmModal({
      title: "Confirm Clear All",
      children: (
        <Text size="sm">
          Are you sure you want to clear all geometries? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => {},
      onConfirm: () => dispatch({ type: ACTION_CLEAR_ALL }),
    });

  return (
    <>
      <AddGeometryModal opened={opened} onClose={close} />

      <div className={classes.panel}>
        <div className={classes.content}>
          <PanelGeoList />
        </div>
        <div className={classes.actionBar}>
          <Tooltip label="Add Geometry">
            <ActionIcon
              variant="subtle"
              size="xl"
              color="#000"
              aria-label="Add"
              onClick={open}
            >
              <IconMapPlus size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Fit">
            <ActionIcon
              variant="subtle"
              size="xl"
              color="#000"
              aria-label="Fit"
              disabled={geometries.length === 0}
              onClick={() => dispatch({ type: ACTION_SET_FOCUS, geometries })}
              style={
                geometries.length === 0 && {
                  backgroundColor: "transparent",
                }
              }
            >
              <IconResize size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Clear All">
            <ActionIcon
              variant={geometries.length === 0 ? "transparent" : "subtle"}
              size="xl"
              color="red"
              aria-label="Clear All"
              disabled={geometries.length === 0}
              onClick={openModal}
              style={
                geometries.length === 0 && {
                  backgroundColor: "transparent",
                }
              }
            >
              <IconCircleMinus size={24} stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Panel;
