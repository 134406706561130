// @flow strict

import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  type Node,
} from "react";
import { showNotification } from "@mantine/notifications";
import { createId } from "@paralleldrive/cuid2";

export type Marker = {
  +lng: number,
  +lat: number,
};

export type Shape = {
  +geom: string,
};

export type Geometry = {
  +id: string,
  +name: ?string,
  +color: string,
  +marker?: Marker,
  +shape?: Shape,
};

// State
type State = {
  +geometries: $ReadOnlyArray<Geometry>,
  +focus: $ReadOnlyArray<Geometry>,
};

const initialState: State = {
  geometries: [],
  focus: [],
};

// Actions
export const ACTION_LOAD = "LOAD";
export const ACTION_ADD_MARKER = "ADD_MARKER";
export const ACTION_ADD_SHAPE = "ACTION_ADD_SHAPE";
export const ACTION_CLEAR_ALL = "CLEAR_ALL";
export const ACTION_DELETE_GEOMETRY = "DELETE_GEOMETRY";
export const ACTION_SET_FOCUS = "SET_FOCUS";
export const ACTION_UPDATE_MARKER = "UPDATE_MARKER";
export const ACTION_UPDATE_SHAPE = "UPDATE_SHAPE";

type Action =
  | { +type: typeof ACTION_LOAD, +state: string }
  | { +type: typeof ACTION_ADD_MARKER, +marker: Marker, +name: ?string }
  | { +type: typeof ACTION_ADD_SHAPE, +shape: Shape, +name: ?string }
  | { +type: typeof ACTION_CLEAR_ALL }
  | { +type: typeof ACTION_DELETE_GEOMETRY, +id: string }
  | { +type: typeof ACTION_SET_FOCUS, +geometries: $ReadOnlyArray<Geometry> }
  | {
      +type: typeof ACTION_UPDATE_MARKER,
      +id: string,
      +marker: Marker,
      +name: ?string,
    }
  | {
      +type: typeof ACTION_UPDATE_SHAPE,
      +id: string,
      +shape: Shape,
      +name: ?string,
    };

const appContext = createContext<void | {
  dispatch: (Action) => void,
  state: State,
}>();

function* colorIterator(colors: $ReadOnlyArray<string>) {
  let index = 0;
  while (true) {
    yield colors[index % colors.length];
    index++;
  }
}

const COLORS: $ReadOnlyArray<string> = [
  "#f3a683",
  "#f7d794",
  "#778beb",
  "#e77f67",
  "#cf6a87",
];

const NEW_COLORS = [
  "#c56cf0",
  "#ffb8b8",
  "#ff3838",
  "#ff9f1a",
  "#fff200",
  "#3ae374",
  "#67e6dc",
  "#17c0eb",
  "#7158e2",
];
const getNextColor = colorIterator(NEW_COLORS);

const serialize = (state: State): string => {
  return btoa(JSON.stringify(state));
};

const deserialize = (state: string): State => {
  try {
    const json = JSON.parse(atob(state));
    return {
      geometries: json.geometries,
      focus: json.focus,
    };
  } catch (e) {
    return initialState;
  }
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ACTION_LOAD:
      return deserialize(action.state);
    case ACTION_ADD_MARKER:
      const geometriesWithMarker = [
        ...state.geometries,
        {
          id: createId(),
          color: getNextColor.next().value || COLORS[0],
          marker: action.marker,
          name: action.name,
        },
      ];

      return {
        ...state,
        geometries: geometriesWithMarker,
        focus: geometriesWithMarker,
      };
    case ACTION_ADD_SHAPE:
      const geometriesWithShape = [
        ...state.geometries,
        {
          id: createId(),
          color: getNextColor.next().value || COLORS[0],
          shape: action.shape,
          name: action.name,
        },
      ];

      return {
        ...state,
        geometries: geometriesWithShape,
        focus: geometriesWithShape,
      };
    case ACTION_CLEAR_ALL:
      return {
        ...state,
        geometries: [],
        focus: [],
      };
    case ACTION_DELETE_GEOMETRY:
      return {
        ...state,
        geometries: state.geometries.filter((g) => g.id !== action.id),
      };
    case ACTION_SET_FOCUS:
      return {
        ...state,
        focus: action.geometries,
      };
    case ACTION_UPDATE_MARKER:
      return {
        ...state,
        geometries: state.geometries.map((g) => {
          if (g.id === action.id) {
            return {
              ...g,
              marker: action.marker,
              name: action.name,
            };
          }
          return g;
        }),
      };
    case ACTION_UPDATE_SHAPE:
      return {
        ...state,
        geometries: state.geometries.map((g) => {
          if (g.id === action.id) {
            return {
              ...g,
              shape: action.shape,
              name: action.name,
            };
          }
          return g;
        }),
      };
    default:
      return state;
  }
};

export const AppContextProvider = ({ children }: { children: Node }): Node => {
  const geomFromStorage = localStorage.getItem("geom");
  const initState = geomFromStorage
    ? deserialize(geomFromStorage)
    : initialState;

  if (initState.geometries.length > 0) {
    for (let i = 0; i < initState.geometries.length; i++) {
      getNextColor.next();
    }
  }

  const [state, dispatch] = useReducer(reducer, initState);

  useEffect(() => {
    try {
      localStorage.setItem("geom", serialize(state));
    } catch (e) {
      if (
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED"
      ) {
        showNotification({
          title: "Error",
          message: "Can't save changes to local storage, too many geoms",
          color: "red",
        });
      } else {
        showNotification({
          title: "Error",
          message: "Failed to save changes to local storage",
          color: "red",
        });
      }
    }
  }, [state]);

  return (
    <appContext.Provider value={{ state, dispatch }}>
      {children}
    </appContext.Provider>
  );
};

export const useAppContext = (): ({
  dispatch: (Action) => void,
  state: State,
  ...
}) => {
  const context = useContext(appContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }

  return context;
};
