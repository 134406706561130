// @flow strict

import React, { type Node } from "react";

import { IconMap } from "@tabler/icons-react";

import classes from "src/styles/Header.module.css";

const Header = (): Node => {
  return (
    <div className={classes.header}>
      <div className={classes.titleContainer}>
        <IconMap size={32} stroke={1.5} />
        <div className={classes.titleText}>PlotMyGeo</div>
      </div>
    </div>
  );
};

export default Header;
