// @flow strict

import React, { useRef, useEffect, type Node } from "react";
import { Text } from "@mantine/core";
import ace from "ace-builds";
import "ace-builds/webpack-resolver";

type Props = {
  +inputData: string,
  +onChange: (string) => void,
  +geomError: ?string,
};

const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const formatData = (data: string): string => {
  if (isJson(data)) {
    return JSON.stringify(JSON.parse(data), null, 2);
  }

  return data;
};

const GeomEditor = ({ inputData, onChange, geomError }: Props): Node => {
  const editorRef = useRef<any>(null);

  useEffect(() => {
    const editor = ace.edit(editorRef.current);
    editor.getSession().setUseWrapMode(true);
    editor.setTheme("ace/theme/chrome");

    // Event listener for changes in the editor
    const onChangeHandler = () => {
      const updatedData = editor.getValue();
      onChange(updatedData);
    };
    editor.session.on("change", onChangeHandler);

    editor.on("paste", function(e) {
      try {
        const formatted = formatData(e.text);
        e.text = formatted;
      } catch (error) {}
    });

    return () => {
      editor.session.off("change", onChangeHandler);
      editor.off("paste");
      editor.destroy();
    };
  }, []);

  useEffect(() => {
    const editor = ace.edit(editorRef.current);

    if (inputData !== editor.getValue()) {
      editor.setValue(inputData, 1);
    }

    if (isJson(inputData)) {
      editor.session.setMode("ace/mode/json");
    } else {
      editor.session.setMode("ace/mode/text");
    }
  }, [inputData]);

  return (
    <div>
      <Text size="sm" fw="600">
        Enter GeoJSON string or WKT string
      </Text>
      <div
        ref={editorRef}
        style={{
          height: "400px",
          width: "100%",
          marginTop: 5,
          marginBottom: 5,
        }}
      />
      {geomError && <Text color="red">{geomError}</Text>}
    </div>
  );
};

export default GeomEditor;
