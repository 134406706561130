// @flow strict

export const hexToRGBA = (hex: string, alpha: number = 0.5): string => {
  const cleanedHex = hex.replace(/^#/, "");

  // Parse the hex color
  let r, g, b;
  if (cleanedHex.length === 3) {
    // If it's a shorthand hex color
    r = parseInt(cleanedHex.charAt(0) + cleanedHex.charAt(0), 16);
    g = parseInt(cleanedHex.charAt(1) + cleanedHex.charAt(1), 16);
    b = parseInt(cleanedHex.charAt(2) + cleanedHex.charAt(2), 16);
  } else if (cleanedHex.length === 6) {
    // If it's a full hex color
    r = parseInt(cleanedHex.substring(0, 2), 16);
    g = parseInt(cleanedHex.substring(2, 4), 16);
    b = parseInt(cleanedHex.substring(4, 6), 16);
  } else {
    throw new Error("Invalid HEX color.");
  }

  // Return the RGBA color with 50% alpha
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
