// @flow strict

import React, { useState, type Node } from "react";

import { Button, Modal, SegmentedControl, Stack, Input } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import {
  useAppContext,
  ACTION_ADD_MARKER,
  ACTION_ADD_SHAPE,
} from "src/AppContext";
import {
  isValidGeoString,
  validateLatitude,
  validateLongitude,
} from "src/helpers/geometryUtil";
import GeomEditor from "src/components/GeomEditor";

type Props = {
  +opened: boolean,
  +onClose: () => void,
};

type GeometryType = "marker" | "geometry";

const AddGeometryModal = ({ opened, onClose }: Props): Node => {
  const { dispatch } = useAppContext();
  const [type, setType] = useState<GeometryType>("marker");

  const [name, setName] = useState<string>("");

  const [geom, setGeom] = useState<string>("");
  const [geomError, setGeomError] = useState<string>("");

  const [lng, setLng] = useState<string>("");
  const [lngError, setLngError] = useState<string>("");

  const [lat, setLat] = useState<string>("");
  const [latError, setLatError] = useState<string>("");

  const submit = () => {
    if (type === "marker") {
      let hasError = false;

      if (!validateLongitude(lng)) {
        setLngError("Invalid Longitude");
        hasError = true;
      }

      if (!validateLatitude(lat)) {
        setLatError("Invalid Latitude");
        hasError = true;
      }

      if (hasError) {
        return;
      }
      reset();
      dispatch({
        type: ACTION_ADD_MARKER,
        marker: { lng: Number(lng), lat: Number(lat) },
        name,
      });
      showNotification({
        title: "Success",
        message: "Marker added",
        color: "teal",
      });
      onClose();
    } else {
      if (isValidGeoString(geom)) {
        dispatch({
          type: ACTION_ADD_SHAPE,
          shape: {
            geom,
          },
          name,
        });
        showNotification({
          title: "Success",
          message: "Geometry added",
          color: "teal",
        });
        reset();
        onClose();
      } else {
        setGeomError("Invalid GeoJSON or WKT string");
      }
    }
  };

  const reset = () => {
    setTimeout(() => {
      setType("marker");
      setGeom("");
      setGeomError("");
      setLng("");
      setLngError("");
      setLat("");
      setLatError("");
      setName("");
    }, 300);
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        reset();
        onClose();
      }}
      title="Add Geometry"
      size="md"
    >
      <Stack>
        <SegmentedControl
          data={[
            {
              label: "Marker",
              value: "marker",
            },
            {
              label: "Geometry",
              value: "geometry",
            },
          ]}
          value={type}
          onChange={setType}
          fullWidth
        />

        <Input.Wrapper label="Name">
          <Input
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
        </Input.Wrapper>

        {type === "marker" ? (
          <>
            <Input.Wrapper label="Longitude" error={lngError} required={true}>
              <Input
                value={lng}
                onChange={(e) => {
                  setLngError("");
                  setLng(e.currentTarget.value);
                }}
                error={lngError}
              />
            </Input.Wrapper>

            <Input.Wrapper label="Latitude" error={latError} required={true}>
              <Input
                value={lat}
                onChange={(e) => {
                  setLatError("");
                  setLat(e.currentTarget.value);
                }}
                error={latError}
              />
            </Input.Wrapper>
          </>
        ) : (
          <GeomEditor
            inputData={geom}
            onChange={(v) => {
              setGeomError("");
              setGeom(v);
            }}
            geomError={geomError}
          />
        )}

        <Button onClick={submit} fullWidth>
          Add
        </Button>
      </Stack>
    </Modal>
  );
};

export default AddGeometryModal;
