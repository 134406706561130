// @flow strict

import React, { type Node } from "react";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";

import { AppContextProvider } from "src/AppContext";
import Header from "src/components/Header";
import Panel from "src/components/Panel.jsx";
import MapView from "src/components/MapView.jsx";
import classes from "src/styles/App.module.css";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

const App = (): Node => {
  return (
    <AppContextProvider>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Notifications position="top-center" />
        <ModalsProvider>
          <div className={classes.container}>
            <Header />
            <div className={classes.content}>
              <Panel />
              <MapView />
            </div>
          </div>
        </ModalsProvider>
      </MantineProvider>
    </AppContextProvider>
  );
};

export default App;
