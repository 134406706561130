// @flow strict

import React, { useState, useEffect, type Node } from "react";

import { Button, Modal, Stack, Input } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

import {
  useAppContext,
  type Geometry,
  ACTION_UPDATE_MARKER,
  ACTION_UPDATE_SHAPE,
} from "src/AppContext";
import {
  isValidGeoString,
  validateLatitude,
  validateLongitude,
} from "src/helpers/geometryUtil";
import GeomEditor from "src/components/GeomEditor";

type Props = {
  +geometry: ?Geometry,
  +opened: boolean,
  +onClose: () => void,
};

const EditGeometryModal = ({ geometry, opened, onClose }: Props): Node => {
  const { dispatch } = useAppContext();
  const [name, setName] = useState<?string>(geometry?.name);

  const [geom, setGeom] = useState<string>(geometry?.shape?.geom || "");
  const [geomError, setGeomError] = useState<string>("");

  const [lng, setLng] = useState<string>(
    geometry?.marker?.lng?.toString() || ""
  );
  const [lngError, setLngError] = useState<string>("");

  const [lat, setLat] = useState<string>(
    geometry?.marker?.lat?.toString() || ""
  );
  const [latError, setLatError] = useState<string>("");

  useEffect(() => {
    setName(geometry?.name);
    setGeom(geometry?.shape?.geom || "");
    setLng(geometry?.marker?.lng?.toString() || "");
    setLat(geometry?.marker?.lat?.toString() || "");
  }, [geometry]);

  const submit = () => {
    if (geometry?.marker != null) {
      let hasError = false;

      if (!validateLongitude(lng)) {
        setLngError("Invalid Longitude");
        hasError = true;
      }

      if (!validateLatitude(lat)) {
        setLatError("Invalid Latitude");
        hasError = true;
      }

      if (hasError) {
        return;
      }
      dispatch({
        type: ACTION_UPDATE_MARKER,
        id: geometry?.id || "",
        marker: { lng: Number(lng), lat: Number(lat) },
        name,
      });
      showNotification({
        title: "Success",
        message: "Marker updated",
        color: "teal",
      });
      onClose();
    } else {
      if (isValidGeoString(geom)) {
        dispatch({
          type: ACTION_UPDATE_SHAPE,
          id: geometry?.id || "",
          shape: {
            geom,
          },
          name,
        });
        showNotification({
          title: "Success",
          message: "Geometry updated",
          color: "teal",
        });
        onClose();
      } else {
        setGeomError("Invalid GeoJSON or WKT string");
      }
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose();
      }}
      title="Edit Geometry"
      size="md"
    >
      <Stack>
        <Input.Wrapper label="Name">
          <Input
            value={name}
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
        </Input.Wrapper>

        {geometry?.marker != null ? (
          <>
            <Input.Wrapper label="Longitude" error={lngError} required={true}>
              <Input
                value={lng}
                onChange={(e) => {
                  setLngError("");
                  setLng(e.currentTarget.value);
                }}
                error={lngError}
              />
            </Input.Wrapper>

            <Input.Wrapper label="Latitude" error={latError} required={true}>
              <Input
                value={lat}
                onChange={(e) => {
                  setLatError("");
                  setLat(e.currentTarget.value);
                }}
                error={latError}
              />
            </Input.Wrapper>
          </>
        ) : (
          <GeomEditor
            inputData={geom}
            onChange={(v) => {
              setGeomError("");
              setGeom(v);
            }}
            geomError={geomError}
          />
        )}

        <Button onClick={submit} fullWidth>
          Save
        </Button>
      </Stack>
    </Modal>
  );
};

export default EditGeometryModal;
