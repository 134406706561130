// @flow strict

import React, { useEffect, type Node } from "react";

import { MapContainer, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";

import { useAppContext } from "src/AppContext";
import { getGeoJson } from "src/helpers/geometryUtil";
import { hexToRGBA } from "src/helpers/colorUtil";

import "leaflet/dist/leaflet.css";

const TILES: $ReadOnlyArray<string> = [
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png",
  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
  "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
];

const customSvg = (color: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="${hexToRGBA(
  color,
  0.25
)}" stroke="${color}">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /><path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
</svg>
`;

const customIcon = (color: string) =>
  L.divIcon({
    className: "custom-icon",
    html: customSvg(color),
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
    shadowSize: [30, 30],
  });

const lineStyle = {
  weight: 3,
};

const polygonStyle = {
  weight: 3,
  opacity: 0.5,
};

const FitBounds = ({
  markers,
  geoJsons,
}: {
  markers: $ReadOnlyArray<Object>,
  geoJsons: $ReadOnlyArray<Object>,
}) => {
  const map = useMap();

  useEffect(() => {
    const bounds = new L.LatLngBounds();

    // Include each marker in the bounds
    markers.forEach((marker) => {
      bounds.extend([marker.lat, marker.lng]);
    });

    geoJsons.forEach((geojsonData) => {
      const geoJsonLayer = L.geoJSON(getGeoJson(geojsonData.geom));
      bounds.extend(geoJsonLayer.getBounds());
    });

    if (bounds.isValid()) {
      map.fitBounds(bounds);
    }
  }, [markers, geoJsons, map]);

  return null;
};

const CustomMarker = ({
  position,
  geoObj,
}: {
  position: $ReadOnlyArray<number>,
  +geoObj: any,
}) => {
  const map = useMap();

  React.useEffect(() => {
    const marker = L.marker(position, { icon: customIcon(geoObj.color) }).addTo(
      map
    );

    if (geoObj.name) {
      marker.bindPopup(geoObj.name);
    }

    return () => {
      map.removeLayer(marker);
    };
  }, [map, position]);

  return null;
};

const GeoJSONLayer = ({ data, geoObj }: { +data: any, +geoObj: any }) => {
  const map = useMap();

  useEffect(() => {
    if (!data) return;

    const geoJsonLayer = new L.GeoJSON(data, {
      pointToLayer: (feature, latlng) => {
        return new L.Marker(latlng, { icon: customIcon(geoObj.color) });
      },
      style: (feature) => {
        switch (feature.geometry?.type) {
          case "LineString":
          case "MultiLineString":
            return { ...lineStyle, color: geoObj.color };
          case "Polygon":
          case "MultiPolygon":
            return { ...polygonStyle, color: geoObj.color };
          default:
            return null;
        }
      },
      onEachFeature: (feature, layer) => {
        if (feature.geometry?.type === "GeometryCollection") {
          feature.geometry.geometries.forEach((geometry) => {
            L.geoJSON(geometry, {
              style: { ...lineStyle, color: geoObj.color },
            }).addTo(map);
          });
        }

        if (geoObj.name) {
          layer.bindPopup(geoObj.name);
        }
      },
    });

    geoJsonLayer.addTo(map);
    return () => {
      map.removeLayer(geoJsonLayer);
    };
  }, [map, data]);

  return null;
};

const MapView = (): Node => {
  const {
    state: { geometries, focus },
  } = useAppContext();

  return (
    <div style={{ flex: 1 }}>
      <MapContainer
        center={[41.8781, -87.6298]}
        zoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url={TILES[3]}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {geometries.map((geometry) => {
          if (geometry.marker) {
            return (
              <CustomMarker
                key={geometry.id}
                geoObj={geometry}
                position={[geometry.marker.lat, geometry.marker.lng]}
              />
            );
          }
          return (
            <GeoJSONLayer
              key={geometry.id}
              geoObj={geometry}
              data={getGeoJson(geometry.shape?.geom || "")}
            />
          );
        })}

        <FitBounds
          markers={focus.map((g) => g.marker).filter(Boolean)}
          geoJsons={focus.map((g) => g.shape).filter(Boolean)}
        />
      </MapContainer>
    </div>
  );
};

export default MapView;
