// @flow strict

import React, { type Node, useState } from "react";

import { Box, Group, Text, ActionIcon, Menu } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconMapPin,
  IconMapPins,
  IconLayersSubtract,
  IconResize,
  IconTrash,
  IconDots,
  IconPencil,
  IconLine,
  IconPolygon,
} from "@tabler/icons-react";

import { useAppContext } from "src/AppContext";
import {
  type Geometry,
  ACTION_DELETE_GEOMETRY,
  ACTION_SET_FOCUS,
} from "src/AppContext.jsx";
import EditGeometryModal from "src/components/EditGeometryModal";

type ContainerProps = {
  +geometry: Geometry,
  +edit: () => void,
  +focus: () => void,
  +remove: () => void,
};

const GEO_TYPE_MAP = {
  POINT: "Point",
  LINESTRING: "Line",
  POLYGON: "Polygon",
  MULTIPOINT: "Multi-Point",
  MULTILINESTRING: "Multi-Line",
  MULTIPOLYGON: "Multi-Polygon",
  FEATURE: "Feature",
  FEATURECOLLECTION: "Feature Collection",
  GEOMETRYCOLLECTION: "Geometry Collection",
};

type TypeData = {
  +name: string,
  +icon: Node,
};

const getIcon = (name: string, color: string): Node => {
  if (name === "POINT") {
    return <IconMapPin size={24} stroke={1.5} color={color} />;
  }

  if (name === "MULTIPOINT") {
    return <IconMapPins size={24} stroke={1.5} color={color} />;
  }

  if (name === "LINESTRING" || name === "MULTILINESTRING") {
    return <IconLine size={24} stroke={1.5} color={color} />;
  }

  if (name === "POLYGON" || name === "MULTIPOLYGON") {
    return <IconPolygon size={24} stroke={1.5} color={color} />;
  }

  return <IconLayersSubtract size={24} stroke={1.5} color={color} />;
};

const getTypeData = (geometry: Geometry): TypeData => {
  let geom = geometry.shape?.geom || "";
  let type = "";

  try {
    const geojson = JSON.parse(geom);
    type = geojson.type;
  } catch (e) {
    type = geom.split("(")[0];
  }

  return {
    name: GEO_TYPE_MAP[type.toUpperCase()] || type,
    icon: getIcon(type.toUpperCase(), geometry.color),
  };
};

const GeoContainer = ({
  geometry,
  edit,
  focus,
  remove,
}: ContainerProps): Node => {
  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: "15px 0",
        borderRadius: 4,
        borderBottom: "1px solid #f1f1f1",
      }}
    >
      <Group justify="space-between" wrap="no-wrap">
        <Group wrap="no-wrap">
          {geometry.marker ? (
            <IconMapPin size={24} stroke={1.5} color={geometry.color} />
          ) : (
            getTypeData(geometry).icon
          )}

          <div>
            {geometry.name && (
              <>
                <Text size="sm" fw={500} lineClamp={1}>
                  {geometry.name}
                </Text>
                <Text size="xs" fw={600} color="gray">
                  {geometry.marker ? "Marker" : getTypeData(geometry).name}
                </Text>
              </>
            )}
            {!geometry.name && (
              <Text size="sm" fw={500}>
                {geometry.marker ? "Marker" : getTypeData(geometry).name}
              </Text>
            )}
          </div>
        </Group>
        <Group>
          <Menu>
            <Menu.Target>
              <ActionIcon variant="subtle" size="md" color="#000">
                <IconDots size={21} stroke={1.5} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item leftSection={<IconPencil size={18} />} onClick={edit}>
                Edit
              </Menu.Item>

              <Menu.Item leftSection={<IconResize size={18} />} onClick={focus}>
                Focus
              </Menu.Item>

              <Menu.Divider />

              <Menu.Item
                leftSection={<IconTrash size={18} color="red" />}
                onClick={remove}
              >
                Remove
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </Box>
  );
};

const PanelGeoList = (): Node => {
  const {
    state: { geometries },
    dispatch,
  } = useAppContext();

  const [selectedGeometry, setSelectedGeometry] = useState<?Geometry>(null);

  const openModal = (id: string) =>
    modals.openConfirmModal({
      title: "Confirm Remove",
      children: (
        <Text size="sm">
          Are you sure you want to remove this geometry? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => {},
      onConfirm: () => dispatch({ type: ACTION_DELETE_GEOMETRY, id }),
    });

  if (geometries.length === 0) {
    return (
      <Text size="md" style={{ textAlign: "center", marginTop: 20 }}>
        No geometries added
      </Text>
    );
  }

  return (
    <>
      <EditGeometryModal
        geometry={selectedGeometry}
        opened={selectedGeometry != null}
        onClose={() => setSelectedGeometry(null)}
      />
      {geometries.map((geometry, idx) => (
        <GeoContainer
          key={geometry.id}
          geometry={geometry}
          edit={() => setSelectedGeometry(geometry)}
          focus={() =>
            dispatch({ type: ACTION_SET_FOCUS, geometries: [geometry] })
          }
          remove={() => openModal(geometry.id)}
        />
      ))}
    </>
  );
};

export default PanelGeoList;
