// @flow strict

import * as GJV from "geojson-validation";
import Wkt from "wicket";

export const validateLongitude = (lng: string): boolean => {
  if (!lng) {
    return false;
  }

  const lngNum = Number(lng);

  if (isNaN(lngNum)) {
    return false;
  }

  return lngNum >= -180 && lngNum <= 180;
};

export const validateLatitude = (lat: string): boolean => {
  if (!lat) {
    return false;
  }

  const latNum = Number(lat);

  if (isNaN(latNum)) {
    return false;
  }

  return latNum >= -90 && latNum <= 90;
};

const validateGeoJSONString = (geojsonString: string): boolean => {
  if (!geojsonString) {
    return false;
  }

  try {
    const geojson = JSON.parse(geojsonString);
    return GJV.valid(geojson);
  } catch (e) {
    return false;
  }
};

const validateWktString = (str: string): boolean => {
  if (!str) return false;

  try {
    const wkt = new Wkt.Wkt();
    wkt.read(str);
    return GJV.valid(wkt.toJson());
  } catch (e) {
    return false;
  }
};

export const isValidGeoString = (str: string): boolean => {
  return validateGeoJSONString(str) || validateWktString(str);
};

export const getGeoJson = (str: string): Object => {
  if (validateGeoJSONString(str)) {
    return JSON.parse(str);
  }

  if (validateWktString(str)) {
    const wkt = new Wkt.Wkt();
    wkt.read(str);
    return wkt.toJson();
  }
};
